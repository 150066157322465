import axios from 'axios';
// import { FP_API_URL } from '../constants/config';

const instance = axios.create({
  // production server
  // baseURL: "https://api.forunpoint.com.pk:3000/v1/api",
  // test server
  baseURL: "https://forunpoint-pakistan-815595f6cd55.herokuapp.com/v1/api",
  headers: {
    'Content-type': 'application/json'
  },
});
instance.interceptors.request.use(function (config) {
  config.headers.Authorization = localStorage.getItem('FpToken')?.replace(/"/g, '');
  return config;
});

export default instance