import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import React, { useState } from "react";
import "./donations.css";
import { DonateUs } from "../../shared/apis";
import "react-datepicker/dist/react-datepicker.css";

function RamadanDonations() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: ""
  });
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [foodForPersons, setPersons] = useState("");
  const [AreaType, setArea]=useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const changeDropDown2 = (e) => {
    setPersons(Number(e.target.value));
  };
  const changeDropDown = (e) => {
    setArea(e.target.value);
  };

  const submit = async () => {
    const body = {
      name: formData.name,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      distributionArea: AreaType,
      totalBoxes: foodForPersons,
    };
  
    if (!body.name) {
      setError("Name is required.");
      return;
    }
    if (!body.email) {
      setError("Email is required.");
      return;
    }
    if (!body.phoneNumber) {
      setError("Phone number is required.");
      return;
    }
    if (!body.distributionArea) {
      setError("Select Distribution Area.");
      return;
    }
    if (!body.totalBoxes) {
      setError("Select Number of Boxes.");
      return;
    }
  
    try {
      setError(""); 
      setSuccessMessage("");
  
      const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/bookings`;
      //console.log("Submitting to API:", apiUrl);
      //console.log("Request Payload:", body);
  
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error Response:", errorText);
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
      }
  
      const result = await response.json();
      console.log("First API Response:", result);

      if (result.status?.message === "success") {
        //console.log("Booking successful, calculating amount...");
        setSuccessMessage("Redirecting to payment...");
  
        const totalBoxes = result.data?.booking?.totalBoxes || 0;
        const amount = totalBoxes * 8 * 100; // 1 box = 8 USD, 1 USD = 100
  
        //console.log(`Total Boxes: ${totalBoxes}, Calculated Amount: ${amount}`);
  
        const anotherApiUrl = `${process.env.REACT_APP_API_URL}api/v1/payments/create-checkout-session`;
        const anotherResponse = await fetch(anotherApiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ amount }),
        });
  
        const anotherResult = await anotherResponse.json();
        //console.log("Second API Response:", anotherResult);
  
        if (anotherResult.url) {
          //console.log("Redirecting to:", anotherResult.url);
          window.location.href = anotherResult.url;
        } else {
          console.error("URL not provided in response.");
          setError("Payment URL not received. Please try again.");
        }
      }
    } catch (error) {
      console.error("Submission error:", error);
      setError(`Error: ${error.message}`);
    }
  };

  return (
    <>
    <Navbar/>
      <div className="donation-banner"></div>
      <div className="joinus-section">
        <div className="joinus-form">
          <h1 className="title">Donate for Iftar</h1>
          <div className="form-container-driver-join">
            <form>
              <label htmlFor="name">Name:</label>
              <br />
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="John Doe"
              />
              <br />
              <label htmlFor="email">Email:</label>
              <br />
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="johndoe@mail.com"
              />
              <br />
              <label htmlFor="phone">Phone:</label>
              <br />
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="+100000000"
              />
              <br />
              <label htmlFor="role">
                Distribution Area:
              </label>
              <select className="multi-select" id="distributionArea" name="distributionArea" onChange={changeDropDown}>
              <option value="">Food Distribution Area</option>
                <option value="Karachi">Karachi</option>
                <option value="Lahore">Lahore</option>
              </select>
              <br />
              <label htmlFor="role">
                Number Of Boxes:
              </label>
              <select className="multi-select" id="totalBoxes" name="totalBoxes" value={foodForPersons} onChange={changeDropDown2}>
              <option value="">Number of Boxes</option>
              <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <br />
              <label htmlFor="comment">
                Any additional information you would like to share?
              </label>
              <br />

              <textarea
                id="information"
                name="information"
                value={formData.comment}
                onChange={handleChange}
                placeholder="Information"
              />
              <br />
            </form>
            <div class="center-container">
              <button className="join-form-btn" onClick={() => submit()}>
                Donate
              </button>
            </div>
            <div className="error-text">{error}</div>
            <div className="success-text" style={{ color: "green", fontWeight: "bold" }}>
              {successMessage}
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default RamadanDonations;
