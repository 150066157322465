import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AboutSection from './componentes/home/about-section/about-section';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ContactUsSection from './componentes/contactus/contactus-section';
import PrivacyPolicy from './componentes/privacy-policy/privacy-policy';
import Terms from './componentes/terms/terms';
import MessageCeo from './componentes/message-ceo/message';
import Faqs from './componentes/faqs/faqs';
import ForunpointSolution from './componentes/forunpoint-solution/forunpoint-solution';
import JoinusSetion from './componentes/joinus/joinus-section';
import DriverJoinus from './componentes/driver-registration/driver';
import RamadanDonations from './componentes/donations/donations';
import RamadanDonationThanks from './componentes/donations/thankyou';
import Career from './componentes/career/career';
import Restaurants from './componentes/restaurants/restaurants';
import RestaurantHome from './componentes/restaurant-home/restaurant-home';
import Cart from './componentes/home/cart/cart';
import OrderDetails from './componentes/order-details/order-details';
import SearchResults from './componentes/search-results/search-result';
import ProfilePage from './componentes/profile-page/profile-page';
import PastOrders from './componentes/past-orders/past-order';
import ActiveOrders from './componentes/active-orders/active-orders';
import Error404 from './componentes/err404/err404';
import OrderDetailsMain from './componentes/order-details-main/order-details-main';
import Gifts from './componentes/gifts/gifts';
import Favourites from './componentes/favourites/favourites';
import Blog from './componentes/home/blogs/blog';
import FirstPage from './componentes/home/blog/firstpage';
// import SecondPage from './componentes/home/page2/secondpage';
// import ThirdPage from './componentes/home/page3/thirdpage';
import QR from './componentes/qr-code/qr';
import QRRider from './componentes/qr-code/qr-rider';
import QRRestaurant from './componentes/qr-code/qr-restaurant';
import QRInsta from './componentes/qr-code/qr-insta';
import Cancel from './componentes/cancel/cancel';
import Stripe from './componentes/stripe-payment/stripe'
import MyGifts from './componentes/my-gifts/my-gifts'
import Wallet from './componentes/wallet/wallet'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "about",
    element: <AboutSection />
  },
  {
    path: "contact-us",
    element: <ContactUsSection />
  },
  {
    path: "stripe",
    element: <Stripe />
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />
  },
  {
    path: "terms",
    element: <Terms />
  },
  {
    path: "message-ceo",
    element: <MessageCeo />
  }
  ,
  {
    path: "faqs",
    element: <Faqs />
  },
  {
    path: "cancel",
    element: <Cancel />
  }
  ,
  {
    path: "forunpoint-solution",
    element: <ForunpointSolution />
  },
  {
    path: "join-us",
    element: <JoinusSetion />
  }
  ,
  {
    path: "driver",
    element: <DriverJoinus />
  },
  {
    path: "donations",
    element: <RamadanDonations />
  },
  {
    path: "donation-thankyou/*",
    element: <RamadanDonationThanks />
  },
  {
    path: "careers",
    element: <Career />
  },

  {
    path: "restaurants/city/:city/area/:area/:restaurant",
    element: <Restaurants />
  },
  {
    path: "restaurants/city/:city",
    element: <Restaurants />
  },
  {
    path: "restaurants",
    element: <Restaurants />
  },
  
  {
    path: "restaurant-home/:id",
    element: <RestaurantHome />,
  },
  {
    path: "cart",
    element: <Cart />
  },
  {
    path: "order-details",
    element: <OrderDetails />
  },
  {
    path: "order-details-main/:id",
    element: <OrderDetailsMain />
  },
  {
    path: "search-results",
    element: <SearchResults />
  },
  {
    path: "profile-page",
    element: <ProfilePage />
  },
  {
    path: "past-orders",
    element: <PastOrders />
  },
  {
    path: "active-orders",
    element: <ActiveOrders />
  },

  {
    path: "gifts",
    element: <Gifts />,
  },
  {
    path: "favourites",
    element: <Favourites />,
  },
  {
    path: "blogs",
    element: <Blog />,
  },
  {
    path: "blog/:blog",
    element: <FirstPage />,
  },
  // {
  //   path: "blog-first",
  //   element: <FirstPage />,
  // },
  // {
  //   path: "blog-second",
  //   element: <SecondPage />,
  // },
  // {
  //   path: "blog-third",
  //   element: <ThirdPage />,
  // },
  {
    path: "my-gifts",
    element: <MyGifts />,
  },
  {
    path: "*",
    element: <Error404 />
  },
  {
    path: "download-app",
    element: <QR />
  },
  {
    path: "download-app-rider",
    element: <QRRider />
  },
  {
    path: "download-app-restaurant",
    element: <QRRestaurant />
  },
  {
    path: "instagram-by-qr",
    element: <QRInsta />
  },

  {
    path: "wallet",
    element: <Wallet />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);
