import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import React, { useState, useEffect } from "react";
import "./thankyou.css";
import "react-datepicker/dist/react-datepicker.css";
import { notify } from "../../shared/apis";

function RamadanDonationThanks() {
  const init = async () => {
    await notify();
  }
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Navbar />
      <div className="donation-banner"></div>
      <div className="donation-section">
        <p><b>Thank You for Your Generous Donation!</b></p>

        <p>Your support means the world to us! Your donation will go a long way in helping provide Iftar meals to those in need during this blessed month of Ramadan. We are deeply grateful for your contribution and kindness.</p>

        <p>Together, we are making a difference. May your generosity bring you countless blessings and peace.</p>

        <p>Thank you once again for being part of this meaningful cause!</p>

        <p>With heartfelt gratitude,</p>

        <p><b>Forun Point</b></p>
      </div>
      <Footer />
    </>
  );
}

export default RamadanDonationThanks;
